//======================================================================================================================
//== APP STYLE ENTRY POINT
//== AUTHOR: Daniel Arbó Marco <daniel.arbo@calonge.cat>
//======================================================================================================================
//@import "../css/style.css";
//@import '../../../node_modules/bootstrap/dist/css/bootstrap.css';

//======================================================================================================================
//== CONSTANTS: EXTERNAL RESOURCES
//======================================================================================================================
$URL_LOGO: '../img/calonge-logo-color.png';
$URL_COOKIES_LOGO: '../img/icons/cookie.png';
$URL_TEXTURES_FABRIC_DARK: '../img/textures/45-degree-fabric-dark.png';
$URL_TEXTURES_FABRIC_PLAID: '../img/textures/fabric-plaid.png';

//======================================================================================================================
//== CONSTANTS: SIZE
//======================================================================================================================
$SIZE_FONT_H1: 2rem;
$SIZE_FONT_H2: 1.75rem;
$SIZE_FONT_H3: 1.5rem;
$SIZE_FONT_H4: 1.3rem;
$SIZE_FONT_H5: 1.2rem;
$SIZE_FONT_H6: 1.1rem;
$SIZE_FONT_P: 1rem;
$SIZE_HEADER_TOP_HEIGHT: 41px;
$SIZE_HEADER_MENU_HEIGHT: 90px;
$SIZE_CONTENT_HEADER_HEIGHT: 4rem; //60px;
$SIZE_MOBILE_HEADER_MENU_HEIGHT: 45px;
$SIZE_MOBILE_HEADER_MENU_WIDTH: 45px;
$SIZE_MOBILE_CONTENT_HEADER_HEIGHT: 6rem; //90px;
$SIZE_BORDER: 1px;

//======================================================================================================================
//== CONSTANTS: COLOR
//======================================================================================================================
$COLOR_FONT_COLOR: #555;
$COLOR_FONT_HOVER_COLOR: #999;
$COLOR_FONT_HOVER_COLOR_LIGHT: #cfd8dc;
$COLOR_BODY_BACKGROUND: #FFF;
$COLOR_HEADER_TOP_BACKGROUND_COLOR: #F6F6F6;
$COLOR_HEADER_CONTENT_BACKGROUND_COLOR: #F5F6F7;
$COLOR_SIDEBAR_BACKGROUND_COLOR: #F5F6F7;
$COLOR_SIDEBAR_HEADER_BACKGROUND_COLOR: #E5E5E5;
$COLOR_SIDEBAR_HOVER_BACKGROUND_COLOR: #D5D5D5;
$COLOR_BORDER: #E5E5E5;
$COLOR_FOOTER_TEXT: #F6F6F6;
$COLOR_FOOTER_INFO_BACKGROUND_COLOR: #00695c; //#3E4551;
$COLOR_FOOTER_COPYRIGHT_BACKGROUND_COLOR: #004d40; //#323741;
$COLOR_INICI_SLIDER_FOOTER_BACKGROUND_COLOR: #00796b; //#00897b; //#00796b; //#eceff1; //#78909c;  //#009688;  //#3E4551;

//======================================================================================================================
//== GLOBAL
//======================================================================================================================
html
{
    overflow-x: hidden;
    min-height: 100%;
}

body
{
    margin-right: 0 !important;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    background: $COLOR_BODY_BACKGROUND;
    font-family: 'Roboto Condensed', sans-serif;
    color: $COLOR_FONT_COLOR;
    min-height: 100%;
}

h1, h2, h3, h4, h5, h6
{
    text-transform: uppercase;
    //font-weight: bold;
    margin: 0;
    padding: 0;
}

h1
{
    font-size: $SIZE_FONT_H1;
}

h2
{
    font-size: $SIZE_FONT_H2;
}

h3
{
    font-size: $SIZE_FONT_H3;
}

h4
{
    font-size: $SIZE_FONT_H4;
}

h5
{
    font-size: $SIZE_FONT_H5;
}

h6
{
    font-size: $SIZE_FONT_H6;
}

p
{
    font-size: $SIZE_FONT_P;
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

a
{
    color: $COLOR_FONT_COLOR;
    &:hover
    {
        text-decoration: none;
        color: $COLOR_FONT_HOVER_COLOR;
    }
}

ul
{
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
}

address
{
    margin: 0;
}

//======================================================================================================================
//== GLOBAL >> DRAWER
//======================================================================================================================
.drawer
{
    > .header
    {
        height: $SIZE_HEADER_MENU_HEIGHT;
        background-color: $COLOR_HEADER_TOP_BACKGROUND_COLOR;
        border-bottom: 1px #EFEFEF solid;

        > #logo
        {
            margin-top: 12px;
            margin-left: 25px;
            float: left;
            max-width: 200px;

            img
            {
                max-width: 200px;
            }
        }
    }

    > .body
    {
        background-color: #4CAF50;

        > aside
        {
            margin-bottom: 0;

            .header
            {
                height: 50px;
                padding: 10px 10px 10px 10px;
                background-color: #E5E5E5;
                border-bottom: 1px #EFEFEF solid;

                h3
                {
                    padding-top: 4px;
                }
            }

            .list-group
            {
                .header
                {
                    border: $SIZE_BORDER solid rgba(0, 0, 0, 0.125);
                    border-bottom: none;
                    padding-left: 1rem;
                }

                .list-group-item
                {
                    background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
                    padding: 0.75rem 1rem;

                    &:first-child, &:last-child
                    {
                        border-radius: 0;
                    }

                    //&:hover
                    //{
                    //    background-color: $COLOR_SIDEBAR_HOVER_BACKGROUND_COLOR;
                    //}

                    svg
                    {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

//======================================================================================================================
//== GLOBAL >> HEADER TOP
//======================================================================================================================
.top_nav
{
    width: 100%;
    height: $SIZE_HEADER_TOP_HEIGHT;
    position: relative;
    top: 0; // TODO: Change to 5px if scroll progress bar is set
    left: 0;
    opacity: 1;
    z-index: 33;
    background: #f6f6f6;
    border-top: $SIZE_BORDER solid #e2e3e2;
    border-bottom: $SIZE_BORDER solid #e2e3e2;

    .widget
    {
        font-family: 'Roboto Condensed', sans-serif;
        float: left;
        padding-left: 15px;
        padding-right: 14px;
        font-size: 12px;
        height: 40px;
        border-left: $SIZE_BORDER solid #e2e3e2;
        border-right: $SIZE_BORDER solid #e2e3e2;

        &:nth-child(2)
        {
            border-left: none;
        }

        span
        {
            line-height: 15px;
            font-size: 13px;
            font-weight: 600;
            font-family: 'Roboto Condensed', sans-serif;
        }

        ul
        {
            float: left;
            list-style: none;
            margin: 5px 0 0 0;
            padding: 0;
        }
    }

    .widget_text
    {
        float: left;
        font-size: 13px;
        padding-top: 0;
        padding-bottom: 0;
        color: #999;
    }

    .textwidget
    {
        font-size: 14px;
        padding-top: 10px;
    }

    .headecart .widget_activation a
    {
        width: 100%;
        text-align: center;
    }

    .footer_social_icons
    {
        margin-top: 2px !important;

        li
        {
            //width: 40px;
            height: 0;
            padding: 0 7px 0 7px;
        }
    }

    .social_widget
    {
        ul > li
        {
            height: auto;
            float: left;
            margin-right: 0;
            opacity: 1 !important;
            position: relative;
            overflow: hidden;

            a
            {
                height: 17px; // IE11 FIX
            }

            i
            {
                float: left;
                width: 100%;
                height: 100%;
                line-height: 26px;
                font-size: 17px;
                display: block;
                text-align: center;
                padding-left: 0;
                position: relative;
                z-index: 9999;
                color: #999;

                &:hover
                {
                    color: #5f6b80
                }
            }
        }

        .social_row
        {
            display: block;
            margin-top: 10px; /*-7*/

            .svg-inline--fa
            {
                height: inherit;
            }
        }
    }
}

//======================================================================================================================
//== GLOBAL >> HEADER MENU
//======================================================================================================================
.header_wrapper
{
    width: 100%;
    position: absolute;
    float: left;
    z-index: 32;

    &.no-transparent
    {
        border-bottom: $SIZE_BORDER #EFEFEF solid;
        height: $SIZE_HEADER_MENU_HEIGHT;
        border-bottom: $SIZE_BORDER #EFEFEF solid;
    }

    header
    {
        position: relative;
        z-index: 10000;

        &#header
        {
            background: rgba(15, 15, 15, 0);

            &.fixed_header
            {
                background: rgba(15, 15, 15, 0.9)
            }

            #navigation
            {
                float: right;
            }
        }

        .nav_top
        {
            margin-top: -1px;
        }

        &.container
        {
            position: relative;
        }

        #logo
        {
            margin-top: 12px;
            margin-left: 10px;
            float: left;
            max-width: 200px;

            img
            {
                max-width: 200px;
            }
        }

        nav
        {
            float: left;
            width: 100%;

            .menu
            {
                float: left;
                padding: 0;
                margin: 0;
                list-style: none;
                text-align: center;
                position: relative;

                li
                {
                    float: left;
                    padding-top: ($SIZE_HEADER_MENU_HEIGHT - 21px) / 2;
                    padding-left: 5px;
                    padding-right: 5px;
                    height: $SIZE_HEADER_MENU_HEIGHT + 1px;

                    > a
                    {
                        line-height: 33px;
                        padding: 2px 12px;
                        font-size: 14px;
                        text-transform: uppercase;
                    }

                    > a > span
                    {
                        font-family: 'Roboto Condensed', sans-serif;
                        padding: 0;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0.8px;
                    }

                    &:hover,
                    &.current-menu-ancestor
                    {
                        &:not(.right_search)
                        {
                            background: #f6f6f6;
                            border-bottom: 4px solid #009dcd;
                        }
                    }

                    &.right_search
                    {
                        border-left: $SIZE_BORDER solid #e1e1e1;
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-top: ($SIZE_HEADER_MENU_HEIGHT - 21px) / 2;
                        height: auto;
                    }

                }

                li:first-child
                {
                    margin-left: 0;

                    > a > span
                    {
                        border-left: none;
                        padding-left: 0;
                    }
                }
            }
        }

        #mobile-menu
        {
            width: $SIZE_MOBILE_HEADER_MENU_WIDTH;
            height: $SIZE_MOBILE_HEADER_MENU_HEIGHT;
            float: right;
            margin-top: ($SIZE_HEADER_MENU_HEIGHT - $SIZE_MOBILE_HEADER_MENU_HEIGHT) / 2;
            margin-right: 10px;

            &.open
            {
                background: url('../img/res_m_open.png') no-repeat;
            }

            &.close
            {
                background: url('../img/res_m_close.png') no-repeat;
            }
        }
    }
}

//.flags
//{
//    img
//    {
//        max-height: 14px;
//        -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
//        filter: grayscale(100%); /* FF 35+ */
//
//        &:hover {
//            cursor: pointer;
//            transition: filter .5s ease-in-out;
//            -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
//            filter: grayscale(0%); /* FF 35+ */
//        }
//    }
//}


.flag
{
    max-height: 14px;
    -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
    filter: grayscale(100%); /* FF 35+ */

    &:hover {
        cursor: pointer;
        transition: filter .5s ease-in-out;
        -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
        filter: grayscale(0%); /* FF 35+ */
    }
}


//======================================================================================================================
//== GLOBAL >> BROSWER NOT SUPPORTED
//======================================================================================================================
#browser-not-supported
{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
    z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
    //color: white;
    //cursor: pointer; /* Add a pointer on hover */

    .box
    {
        text-align: center;
        margin: 100px auto;
        padding: 40px 20px 40px 20px;
        width: 60%;
        background: #fff url($URL_TEXTURES_FABRIC_PLAID);

        h1, h2, h3, h4
        {
            font-weight: bold;
            text-transform: inherit;
        }

        h1
        {
            font-size: 36px;

            margin-left: auto;
            margin-right: auto;

        }

        a
        {
            color: white;
        }

        //.buttons
        //{
        //    width: 50%;
        //    margin-left: auto;
        //    margin-right: auto;
        //}
    }

}

//======================================================================================================================
//== GLOBAL >> CONTENT
//======================================================================================================================
#content
{
    min-height: 300px;
}

.content_wrapper
{
    z-index: 1;
    margin-bottom: 0;
    position: relative;
    float: left;
    width: 100%;
    background: #fff;
    padding-top: $SIZE_HEADER_MENU_HEIGHT;

    .page
    {
        &.header
        {
            background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
            padding-top: 20px;
            padding-bottom: 20px;

            h1
            {
                //padding-left: 10px;
            }

            .breadcrumbs
            {
                color: #7c7c7c;
                font-size: 15px;
                padding-top: 10px;
                padding-right: 10px;

                ul
                {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

//======================================================================================================================
//== GLOBAL >> CONTENT >> SECTION
//======================================================================================================================
section,
.container
{
    .header
    {
        padding: 10px 10px 10px 10px;
        background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
        border: $SIZE_BORDER solid #E5E5E5;
    }

    .loader
    {
        text-align: center;
        padding: 20px 0 50px 0;
    }

    .body
    {
        padding: 0 10px 0 10px;
    }

    h3
    {
        margin-top: 0;
        margin-bottom: 0;
    }

    p
    {
        margin-top: 5px;
        margin-bottom: 0;
    }
}

//======================================================================================================================
//== GLOBAL >> CONTENT >> SIDEBAR
//======================================================================================================================
.container
{
    aside
    {
        .body
        {
            border: $SIZE_BORDER solid $COLOR_BORDER;
            border-top: none;
            padding: 0;
            background-color: $COLOR_SIDEBAR_BACKGROUND_COLOR;
        }

        .header
        {
            background-color: $COLOR_SIDEBAR_HEADER_BACKGROUND_COLOR;
            border-top: $SIZE_BORDER solid $COLOR_BORDER;
            border-bottom: $SIZE_BORDER solid $COLOR_BORDER;
        }

        .list-group
        {
            .header
            {
                border: $SIZE_BORDER solid rgba(0, 0, 0, 0.125);
                border-bottom: none;
                padding-left: 1rem;
            }

            .list-group-item
            {
                background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
                padding: 0.75rem 1rem;

                opacity: 1;
                transition: opacity .5s ease-out;
                -moz-transition: opacity .5s ease-out;
                -webkit-transition: opacity .5s ease-out;
                -o-transition: opacity .5s ease-out;

                &:first-child, &:last-child
                {
                    border-radius: 0;
                }

                &:hover
                {
                    //background-color: $COLOR_SIDEBAR_HOVER_BACKGROUND_COLOR;
                    opacity: 0.8;
                }

                svg
                {
                    margin-right: 7px;
                }
            }

            .list-group-subitem
            {
                border: 1px solid rgba(0, 0, 0, 0.125);
                margin-bottom: -1px;
                background-color: #E0E0E0;
                padding: 0.75rem 1rem 0.75rem 2rem;

                opacity: .8;
                transition: opacity .5s ease-out;
                -moz-transition: opacity .5s ease-out;
                -webkit-transition: opacity .5s ease-out;
                -o-transition: opacity .5s ease-out;

                &:first-child, &:last-child
                {
                    border-radius: 0;
                }

                &:hover
                {
                    //background-color: $COLOR_SIDEBAR_HOVER_BACKGROUND_COLOR;
                    opacity: 1;
                }

                svg
                {
                    margin-right: 7px;
                }
            }
        }

        .twitter-timeline-widget
        {
            background-color: #F5F6F7;
            border-radius: 0;
        }
    }
}

//======================================================================================================================
//== GLOBAL >> CONTENT >> FOOTER
//======================================================================================================================
footer
{
    color: $COLOR_FOOTER_TEXT;
    font-size: 0.9rem;
    font-weight: normal;

    h5
    {
        font-weight: normal;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.4);
    }

    a
    {
        color: $COLOR_FOOTER_TEXT;
        //text-transform: uppercase;
    }

    .info
    {
        background-color: $COLOR_FOOTER_INFO_BACKGROUND_COLOR;
        text-align: justify;
        border-bottom: $SIZE_BORDER solid rgba(0, 0, 0, 0.4);

        //&::after {
        //    content: "";
        //    transform: rotate(-20deg);
        //    background-image: url("/img/calonge/calonge-logo-escut-white.png");
        //    background-repeat: no-repeat;
        //    background-position: center;
        //    background-size: contain;
        //    opacity: 0.4;
        //    top: 0;
        //    left: 0;
        //    bottom: 0;
        //    right: 0;
        //    position: absolute;
        //    z-index: -1;
        //}

        .img
        {
            display: flex;
            align-items: center;

            img
            {
                width: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }

    .copyright
    {
        background-color: $COLOR_FOOTER_COPYRIGHT_BACKGROUND_COLOR;
        text-align: center;
    }
}

//======================================================================================================================
//== INICI >> WEBS AMIGUES
//======================================================================================================================
#webs-amigues
{
    .item
    {
        margin-top: 15px;

        &:first-child
        {
            margin-top: 0;
        }

        img
        {
            width: 100%;
            transition: .2s ease;
            opacity: 0.8;

            &:hover
            {
                transition: .2s ease;
                opacity: 1;
            }
        }
    }
}

//======================================================================================================================
//== INICI >> CONTENT >> SECTION >> SLIDER
//======================================================================================================================
#home-slider
{
    $CAROUSEL_HEIGHT: 400px;
    .carousel
    {
        max-height: $CAROUSEL_HEIGHT;
        //display: table-cell;  IE NOT WORKING
        vertical-align: bottom;
        .slide
        {
            cursor: pointer;
            img
            {
                max-height: $CAROUSEL_HEIGHT;
                object-fit: cover;
                object-position: bottom;
            }

        }
    }

    //padding-bottom: 30px;
    //
    //.image-gallery
    //{
    //    .image-gallery-content
    //    {
    //        background-color: #F5F6F7;
    //    }
    //
    //    .image-gallery-slide
    //    {
    //        img
    //        {
    //            max-height: 400px;
    //        }
    //    }
    //
    //    .fullscreen
    //    {
    //        .image-gallery-slide
    //        {
    //            img
    //            {
    //                max-height: none;
    //            }
    //        }
    //    }
    //}
}

#home-slider-footer
{
    background-color: $COLOR_INICI_SLIDER_FOOTER_BACKGROUND_COLOR;
    border-bottom: $SIZE_BORDER solid rgba(0, 0, 0, 0.4);
    font-size: 0.9rem;

    .header
    {
        //margin-bottom: 1px;
        border: none;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.4);
        color: $COLOR_FOOTER_TEXT;

        h5
        {
            font-weight: normal;
        }

        .arrow
        {
            //font-size: 18px;
            //padding-top : 5px;
            cursor: pointer;
        }

        .back
        {
            margin-right: 5px;
            &:hover
            {
                color: $COLOR_FONT_HOVER_COLOR_LIGHT;
            }
        }

        .next
        {
            margin-left: 5px;
            &:hover
            {
                color: $COLOR_FONT_HOVER_COLOR_LIGHT;
            }
        }
    }

    .slide
    {
        background: #FFFFFF url($URL_TEXTURES_FABRIC_DARK);
        transition: .2s ease;
        opacity: 0.8;
        padding: 15px;
        min-height: 160px;
        //min-height: 20vh;  // IE11 NOT WORKING
        //margin-top: 5px;

        &:hover
        {
            cursor: pointer;
            background-color: #00bfa5;
            transition: .2s ease;
            color: #fff;
        }
    }

    .slick-slider
    {
        //padding-left: -5px;
        margin-left: -5px;

        .slick-list
        {

            .slick-slide
            {
                padding-left: 5px;
            }

            //.slick-current
            //{
            //    padding-left: 0;
            //}
        }
    }
}

//======================================================================================================================
//== INICI >> CONTENT >> SECTION >> LAUNCHER
//======================================================================================================================
.launcher
{
    transition: .2s ease;
    background-color: lightgray;
    padding: 10px;
    min-height: 124px;

    &:hover
    {
        background-color: #E3E3E3;
        transition: .2s ease;
    }

    .title
    {

    }

    .message
    {

    }
}

//======================================================================================================================
//== INICI >> CONTENT >> SECTION >> AGENDA
//======================================================================================================================
.event
{
    opacity: 1;
    transition: .5s ease;

    &:hover
    {
        //opacity: 0.7;
    }

    p
    {
        font-family: 'Roboto Condensed', sans-serif;
    }

    .calendar
    {
        background-color: #F5F6F7;
        border: $SIZE_BORDER solid #E5E5E5;

        .day
        {
            padding-top: 2px;
            padding-bottom: 2px;
            font-weight: bold;
            text-align: center;
        }

        .month
        {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: #009688;
            color: #fff;
            font-size: 17px;
            font-weight: bold;
            text-align: center;
            text-transform: lowercase;
        }

        .hour
        {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: #8bc34a;
            color: #fff;
            font-size: 17px;
            font-weight: bold;
            text-align: center;
        }
    }

}

.controls
{
    background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
    border: $SIZE_BORDER solid #E5E5E5;

    .react-fuzzy-search
    {
        input // Cloned from TB4 .form-control and .form-control-sm (needed for 'fuzzy-search' styling)
        {
            display: block;
            width: 100%;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
            -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
            height: calc(1.8125rem + 2px);
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            border-radius: 0.2rem;
        }
    }

    .react-datepicker-wrapper
    {
        width: 100%;

        .react-datepicker__input-container
        {
            width: 100%;
        }
    }

    .react-datepicker-popper
    {
        z-index: 3000000;
    }
}

//.event-tooltip
//{
//    pointer-events   : auto !important;
//    opacity          : 1 !important;
//    padding          : 2px !important;
//    background-color : #E5E5E5 !important;
//
//}
//
//.event-tooltip .place-top:after
//{
//    border-top       : #E5E5E5 !important;
//    border-top-style : solid !important;
//    border-top-width : 6px !important;
//}

//======================================================================================================================
//== INICI >> CONTENT >> SECTION >> NOTICIES
//======================================================================================================================
.noticia
{
    h2
    {
        text-transform: initial;
        font-weight: bold;
    }

    h3
    {
        text-transform: initial;
    }

    h4
    {
        text-transform: initial;
    }

    h5
    {
        text-transform: initial;
        padding: 5px;
    }

    time
    {
        font-weight: 500;
    }

    img // From twitter bootstrap  .img-thumbnail (cannot extend...)
    {
        display: inline-block;
        max-width: 100%;
        height: auto;
        padding: 4px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .image
    {
        //border: $SIZE_BORDER solid #E5E5E5;
        position: relative;

        img
        {
            width: 100%;
            opacity: 1;
            display: block;
        }

        &.link
        {
            img
            {
                width: 100%;
                opacity: 1;
                display: block;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;
            }

            &:hover img
            {
                opacity: 0.9;
                //cursor: pointer;
            }

            &:hover .middle
            {
                opacity: 1;
            }

            .middle
            {
                transition: .2s ease;
                opacity: 0;
                position: absolute;
                //top: 50%;
                //left: 50%;
                bottom: 40px;
                right: 30px;
                //transform: translate(-50%, -50%);
                //-ms-transform: translate(-50%, -50%);
                text-align: center;
            }

            .text
            {
                //background-color: #4CAF50;
                background-color: #00bfa5;
                color: white;
                font-size: 16px;
                padding: 16px 32px;
            }
        }

    }

    .alert
    {
        hr
        {
            margin-top: 0.5rem;
        }
    }

    .social-share-icon
    {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
    }

    .social-share-text
    {
        display: inline-block;
        padding-right: 5px;
        text-align: center;
        vertical-align: top;
        cursor: pointer;
    }

    .read-more
    {
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;

        a
        {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: $COLOR_HEADER_CONTENT_BACKGROUND_COLOR;
            width: 100%;
            border: $SIZE_BORDER solid #e2e3e2;

            &:hover
            {
                background-color: #E5E5E5;
            }
        }
    }
}

.masonry-grid
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.masonry-grid_column
{
    padding-left: 30px;
}

.masonry-grid_column > div
{
    margin-bottom: 30px;
}

.card
{
    opacity: 1;

    &:hover
    {
        transition: .5s ease;
        opacity: 0.9;
    }

    .card-img-top
    {
        height: inherit;
    }

    .card-body
    {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        font-weight: bold;
    }
}

#noticies-list
{
    h2
    {
        margin-top: 5px;
        margin-bottom: 10px;
        line-height: 1.2em;
    }

    .image
    {
        border: $SIZE_BORDER solid #E5E5E5;
    }
}

//======================================================================================================================
//== INICI >> CONTENT >> SECTION >> RESULTATS ELECTORALS
//======================================================================================================================
.resultats-electorals
{
    table
    {
        font-size: 1rem;
        //font-size: 13px;
        //border: 1px solid #DEE2E6;

        thead
        {
            font-size: 1rem;
            //font-size: 14px;
            text-transform: uppercase;

            th
            {
                text-align: center;
            }
        }

        td
        {
            text-align: right;
        }
    }

    .chart
    {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        //padding: 10px;
        width: 100%;
        height: 500px;
        background-color: #fff;

        .recharts-responsive-container
        {
            height: 50% !important;
            width: 100% !important;
            min-height: 500px;

            .recharts-wrapper
            {
                height: 55% !important;
                width: 100% !important;

                svg
                {
                    //height: 150% !important;

                    .recharts-surface
                    {
                        //height: 50% !important;
                    }
                }
            }
        }
    }

    .chart-mini
    {
        > .title
        {
            background-color: #90a4ae;
            padding: 5px 10px 5px 10px;
            border: 1px solid #607d8b;
            color: #FFFFFF;
            //font-weight: bold;
        }

        > .body
        {
            background-color: #f3f3f3;
            //padding: 20px 20px 10px 5px;
            padding: 20px 10px;
            border: 1px solid #e3e3e3;
        }
    }
}

.fullscreen-enabled
{
    overflow: auto; // Show scrollbar

    .results
    {
        background: #FFF;
        margin: 0 !important;
        padding: 30px;
        min-height: 100%;
    }
}

//======================================================================================================================
//== AJUNTAMENT >> PLE
//======================================================================================================================
.ple, .sindic
{
    .img-fluid
    {
        width: 100%;
    }
}

//.regidor
//{
//    padding-top: 15px;
//
//    &:first-child
//    {
//        padding-top: 0;
//    }
//
//    .img
//    {
//        padding-right: 20px;
//        padding-bottom: 20px;
//    }
//
//    .description
//    {
//        > h4
//        {
//            margin-top: 0;
//            margin-bottom: 0;
//        }
//        > p
//        {
//            margin-top: 7px;
//            margin-bottom: 13px;
//        }
//    }
//}

//======================================================================================================================
//== PAGE
//======================================================================================================================
#img-top
{

    img
    {
        width: 100%;
    }
}

//======================================================================================================================
//== YOUTUBE EMBEDDED VIDEOS
//======================================================================================================================
.videoWrapper
{
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe[src^='https://www.youtube.com']
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

#videoteca
{
    .videoWrapper
    {
        @extend .videoWrapper;
        margin-top: 10px;

        &:first-child
        {
            margin-top: 0;
        }
    }
}

//======================================================================================================================
//== TREEMAP CHART
//======================================================================================================================
#treemap
{
    $m: 0; // margin

    *, *:before, *:after
    {
        box-sizing: border-box;
    }

    .feature
    {
        position: relative;
        width: calc(100% - #{$m} * 2 * 1px);
        height: calc(100% - #{$m} * 2 * 1px);
        margin: $m * 1px;
        //background: #aaa;
        overflow: hidden;
    }

    .node
    {
        position: absolute;
        //background: transparent url('') no-repeat 50% / cover;
        //border: 1px white solid;
        overflow: hidden;
        //opacity: 0.8;
        transition: opacity .8s;
        cursor: pointer;

        .label
        {
            display: inline;
            font-family: sans-serif;
            font-size: small;
            color: hsla(0, 0, 100, .6);
            //white-space: nowrap;
            position: absolute;
            padding: 0;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: color .4s, opacity .8s, filter .8s;
        }

        &.hide
        { // zoom in
            opacity: 0;
            pointer-events: none;
            .label
            {
                filter: blur(10px);
            }
        }

        &:hover
        {
            //background-color: #222 !important;
            //opacity: 1;
            .label
            {
                color: hsla(0, 0, 100, 1);
            }
        }

        &.level-0
        {
            z-index: 4;
            font-size: 15vmin;
            display: none;
        }

        &.level-1
        {
            z-index: 3;
            font-size: 10vmin;
        }

        &.level-2
        {
            z-index: 2;
            font-size: 5vmin;
        }

        &.level-3
        {
            z-index: 1;
            font-size: 2.5vmin;
        }

    }
}

//======================================================================================================================
//== SCROLL PROGRESS BAR
//======================================================================================================================
.scroll-progress-bar
{
    background-color: rebeccapurple;
    height: 5px;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
}

//======================================================================================================================
//== COOKIES BANNER
//======================================================================================================================
.react-cookie-banner
{
    font-family: "Roboto Condensed", serif;
    height: 100% !important;
    background: rgba(52, 64, 81, 0.88) url($URL_COOKIES_LOGO) 20px 50% no-repeat !important;
    background-size: 30px 30px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 20px !important;

    span.cookie-message
    {
        display: block;
        padding: 0 100px 0 67px !important;
        line-height: 1.5 !important;
        text-align: left;
        color: white;
    }

    button
    {
        border: 1px solid white !important;
        border-radius: 0.2rem !important;
        width: 66px;
        height: 32px;
        line-height: 32px !important;
        background: #1d5d90 !important;
        color: white !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        opacity: 1;
        right: 20px !important;
        margin-top: -18px !important;
    }
}

//======================================================================================================================
//== COOKIES BANNER
//======================================================================================================================
.scroll-up
{
    z-index: 9999999999999;
}

//======================================================================================================================
//== SCROLL TOP ARROW
//======================================================================================================================
img.scroll-top-arrow
{
    background-color: lightgray;
    padding: 4px;
    border-radius: 4px;
    transition: .2s ease;
    opacity: 0.6;

    &:hover
    {
        transition: .2s ease;
        opacity: 0.8;
        animation-duration: 200ms;
    }
}

//======================================================================================================================
//== HORIZONTAL SCROLL
//======================================================================================================================
.scroll-x
{
    overflow-x: auto;
}

//======================================================================================================================
//== OVERRIDE TABLE CELL PADDING
//======================================================================================================================
.table th, .table td
{
    padding: 0.2rem;
}

//======================================================================================================================
//== OVERRIDE react-big-calendar
//======================================================================================================================
.rbc-calendar
{
    .rbc-toolbar
    {
        button
        {
            border-radius: 0;
        }

        .rbc-toolbar-label
        {
            font-weight: 600;
        }
    }

    .rbc-row
    {
        font-size: 12px;

        .rbc-event
        {
            border-radius: 0;
            padding: 0;
            line-height: 1;
        }
    }
}


.services-header
{
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fafafa;

    .nav
    {
        .nav-item
        {
            .nav-link
            {
                //padding: 0.5rem 1rem;

                &.active
                {
                    //background-color: #abdde5;

                    .selected
                    {
                        //padding: 5px;
                        background-color: #e0e0e0;
                    }
                }

                .selected
                {
                    padding: 3px 10px;
                }
            }

            &:last-child
            {
                .nav-link
                {
                    //padding-right: 0;
                }
            }
        }
    }
}

.sub-service
{
    padding: 10px;
}

//======================================================================================================================
//== TWITTER BOOTSTRAP 4 DEFAULTS (TODO: MILLORAR)
//======================================================================================================================
.html-content // Cloned from TB 4
{
    h1, h2, h3, h4, h5, h6
    {
        margin-top: 0;
        margin-bottom: 0.5rem;
        text-transform: none;
        font-weight: normal;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6
    {
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
    }

    h1, .h1
    {
        font-size: 2.5rem;
    }

    h2, .h2
    {
        font-size: 2rem;
    }

    h3, .h3
    {
        font-size: 1.75rem;
    }

    h4, .h4
    {
        font-size: 1.5rem;
    }

    h5, .h5
    {
        font-size: 1.25rem;
    }

    h6, .h6
    {
        font-size: 1rem;
    }

    p
    {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    a
    {
        color: #007bff !important;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }

    a:hover
    {
        color: #0056b3;
    }

    a:not([href]):not([tabindex])
    {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus
    {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):focus
    {
        outline: 0;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol
    {
        margin-bottom: 0;
    }

    ul
    {
        list-style-type: disc !important;
        padding-left: 1em !important;
        margin-left: 1em;

        > li
        {
            ul
            {
                margin-top: 1em;
            }
        }
    }
}

.timetable
{
    .days
    {
        text-transform: capitalize;
    }
}

//======================================================================================================================
//== DRUPAL TEXT ALIGN CENTER
//======================================================================================================================
.text-align-center
{
    text-align: center;
}

article
{
    .field
    {
        width: 100% !important;
    }
}

//======================================================================================================================
//== TWITTER BOOTSTRAP 4 OVERRIDE RESPONSIVE PARAMS
//======================================================================================================================
.table .thead-dark th
{
    border-color: #dee2e6;
}

.btn-group-xs > .btn, .btn-xs {
    padding  : .25rem .4rem;
    font-size  : .875rem;
    line-height  : .5;
    border-radius : .2rem;

    vertical-align: text-bottom;
}

body.modal-open
{
    padding-right: 0!important;
}

@media (min-width: 0px)
{
    .card-img-top
    {
        width: 100%;
        height: 60vw;
        object-fit: cover;
    }

    .rbc-calendar
    {
        min-height: 400px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px)
{
    .card-img-top
    {
        height: 50vw;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px)
{
    .card-img-top
    {
        height: 20vw;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px)
{
    .card-img-top
    {
        height: 18vw;
    }

    .rbc-calendar
    {
        min-height: inherit;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px)
{

}

// Custom
@media (min-width: 0px) and (max-width: 991px)
{
    .container
    {
        max-width: none;
    }

    section
    {
        .header
        {
            padding: 20px 20px 20px 20px;
        }
    }
}
